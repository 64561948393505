import React, { Fragment, useContext, useState } from "react";
import PropTypes from "prop-types";
import Wrapper from "components/LayoutFront/Wrapper";
import Block from "components/templatesComponents/Block";
import Box from "@material-ui/core/Box";
import Text from "components/templatesComponents/Text";
import Icon from "components/templatesComponents/Icon";
import PageTitle from "components/templatesComponents/PageTitle";
import article from "components/templates/article/article";
import { makeStyles, useTheme } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Hidden from "components/templatesComponents/Hidden";
import Separator from "components/templatesComponents/Separator";
import CanSendTagContext from "components/CanSendTagContext";
import ATInternetService from "services/ATInternetService";
import EulerianService from "services/EulerianService";
import PageService from "services/PageService";
import useAxiosCache from "hooks/axiosCache";
import LanguageContext from "components/LanguageContext";
import SiteContext from "components/SiteContext";
import t from "utils/locales/translation.json";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    [theme.breakpoints.up("lg")]: {
      position: "relative",
    },
  },
  labelWrapper: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  label: {
    display: "inline-block",
    padding: theme.spacing(0.5, 1.5),
    border: `1px solid ${theme.palette.componentColors[70]}`,
    color: theme.palette.componentColors[70],
    fontSize: "0.77rem",
    [theme.breakpoints.up("lg")]: {
      backgroundColor: theme.palette.secondary[20],
      border: "none",
    },
  },
  toKnow: {
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.up("lg")]: {
      backgroundColor: "inherit",
      "& h3": {
        margin: theme.spacing(2, 0, 1, 0),
      },
    },
    [theme.breakpoints.down("md")]: {
      "& h3": {
        fontSize: "0.875rem",
        margin: theme.spacing(0, 0, 1, 0),
        lineHeight: "18px",
      },
    },
  },
  documentsRequiredBlock: {
    backgroundColor: theme.palette.primary.main,
    overflowWrap: "break-word",
    "& a": {
      color: "white",
      textDecoration: "underline",
      fontWeight: 300,
    },
    "& *": {
      color: "white",
    },
    "& h2": {
      marginBottom: theme.spacing(3),
    },
    padding: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(4),
      position: "absolute",
      right: 0,
    },
  },
  stepsBlock: {
    backgroundColor: theme.palette.primary[10],
    [theme.breakpoints.up("lg")]: {
      marginTop: theme.spacing(5),
      paddingBottom: theme.spacing(8),
      "& h2": {
        maxWidth: "650px",
      },
    },
  },
  textTruncat: {
    [theme.breakpoints.down("md")]: {
      "@media screen": {
        overflow: "hidden",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: "3",
      },
    },
  },
  stepperContainer: {
    "@media print": {
      display: "block",
    },
  },
  seeMore: {
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
    display: "flex",
    marginTop: theme.spacing(2),
    justifyContent: "flex-end",
    alignItems: "center",
    fontSize: "0.77rem",
    "& i, span::before": {
      marginLeft: theme.spacing(1),
    },
    "@media print": {
      display: "none",
    },
  },
  demarcheContainer: {
    [theme.breakpoints.up("lg")]: {
      borderTop: `1px solid ${theme.palette.componentColors[70]}`,
    },
  },
  stepper: {
    backgroundColor: "inherit",
  },
  leftSide: {
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(2, 2, 0, 0),
    },
  },
  startLink: {
    display: "inline-block",
  },
  button: {
    marginTop: theme.spacing(4),
  },
  title: {
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0, 2),
    },
  },
  section: {
    padding: theme.spacing(5, 2, 0, 2),
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(10, 0, 0, 0),
    },
    "& h2": {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up("lg")]: {
        marginBottom: theme.spacing(3),
      },
    },
  },
}));

const Process = (props) => {
  const { page: currentPage } = props;
  const { title, contents, page, pageId } = currentPage;
  const {
    processDescription,
    toKnow,
    startLink,
    startLinkFullWidth,
    documentsRequiredCount,
    documentsRequired,
    completionTime,
    stepper,
    relatedQuestions,
  } = contents;
  const { originalPageId } = page || {};
  const site = useContext(SiteContext);
  const { id: siteId } = site || {};

  let { videosList } = contents;
  if (videosList && !Array.isArray(videosList)) {
    videosList = [videosList];
  }

  let { panels } = relatedQuestions.props || {};

  if (panels && !Array.isArray(panels)) {
    panels = [panels];
  }

  const classes = useStyles();
  const theme = useTheme();

  const { language } = useContext(LanguageContext);

  const [seeMoreKnow, setseeMoreKnow] = useState(false);
  const [seeMoreDocument, setseeMoreDocument] = useState(false);

  const handleClickStartLink = () => {
    ATInternetService.sendClick({
      name: title,
      chapter2: "commencer_votre_demarche",
      type: "action",
    });
    EulerianService.sendClick({ button: [`button::click::${title}`] });
  };

  const [{ data: associatedPnuPages = [] }] = useAxiosCache(
    PageService.getConfig("findPages", {
      siteId,
      pageId: originalPageId || pageId,
    })
  );

  const [{ data = {} }] = useAxiosCache(
    PageService.getConfig("findPagesByTemplateKeys", {
      siteName: "ANTS",
      templates: [article.key],
      filters: [`page.name||eq||Art - Simplimmat`],
    })
  );

  const simplimmatPage = data?.pages?.[0] || data?.[0];

  const displaySimplimmat = ["/vendre-ou-donner-votre-vehicule", "/acheter-ou-recevoir-un-vehicule-doccasion"].some(
    (url) => associatedPnuPages.map((associatedPage) => associatedPage.path).includes(url)
  );

  // TODO voir pour factoriser le lien commencer la demarche
  return (
    <Fragment>
      <Wrapper className={classes.wrapper}>
        <Hidden mdDown>
          <div className={classes.label}>{processDescription}</div>
        </Hidden>
        <Grid container className={classes.demarcheContainer}>
          <Grid item sm={12} lg={8} className={classes.leftSide}>
            <div className={classes.title}>
              <PageTitle>{title}</PageTitle>{" "}
            </div>
            <Hidden lgUp>
              <div className={classes.labelWrapper}>
                <div className={classes.label}>{processDescription}</div>
              </div>
            </Hidden>
            <Block className={classes.toKnow}>
              <h3>{t[language].process.to_know}</h3>
              <Text>
                <div className={!seeMoreKnow && classes.textTruncat}>{toKnow}</div>
                <div className={classes.seeMore} onClick={() => setseeMoreKnow(!seeMoreKnow)} role="button">
                  {!seeMoreKnow ? t[language].common.see_more : t[language].common.see_less}{" "}
                  <Icon
                    icon={!seeMoreKnow ? "chevron-down" : "chevron-up"}
                    iconDSFR={!seeMoreKnow ? "arrow-down-s-line" : "arrow-up-s-line"}
                    title={!seeMoreKnow ? t[language].common.see_more : t[language].common.see_less}
                  />
                </div>
              </Text>
            </Block>
            <Hidden mdDown>
              <div onClick={handleClickStartLink}>
                <CanSendTagContext.Provider value={false}>{startLink}</CanSendTagContext.Provider>
              </div>
            </Hidden>
            {displaySimplimmat && (
              <Block>
                <div style={{ margin: theme.spacing(2.5, 0) }}>
                  <h3>{t[language].process.did_you_know}</h3>
                  <p>
                    {t[language].process.simplimmat_message1}
                    <a className="fr-link" href={simplimmatPage?.fullPath}>
                      Simplimmat
                    </a>
                    {t[language].process.simplimmat_message2}
                  </p>
                </div>
              </Block>
            )}
          </Grid>
          <Grid item sm={12} lg={4} className={classes.documentsRequiredBlock}>
            <div className={classes.documentsRequiredContent}>
              <h2>{t[language].process.document_required_title}</h2>
              <p className="fr-text-sm" role="presentation">
                {documentsRequiredCount}
              </p>
              <div className={!seeMoreDocument && classes.textTruncat}>{documentsRequired}</div>
              <div className={classes.seeMore} onClick={() => setseeMoreDocument(!seeMoreDocument)} role="button">
                {!seeMoreDocument ? t[language].common.see_more : t[language].common.see_less}
                <Icon
                  icon={!seeMoreDocument ? "chevron-down" : "chevron-up"}
                  iconDSFR={!seeMoreDocument ? "arrow-down-s-line" : "arrow-up-s-line"}
                  title={!seeMoreDocument ? t[language].common.see_more : t[language].common.see_less}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Wrapper>

      {/* Steps */}
      <Wrapper leftProps={{ className: classes.stepsBlock }} className={classes.stepsBlock}>
        <Separator />
        <Block>
          <h2>{t[language].process.steps_title}</h2>
          <p>
            <Icon
              icon="clock"
              iconDSFR="time-line"
              style={{
                color: theme.palette.secondary.main,
                fontSize: "16px",
                marginRight: "6px",
              }}
            />
            <b>{completionTime}</b> {t[language].process.time_message}
          </p>
        </Block>
        <Grid container className={classes.stepperContainer}>
          <Grid item sm={12} lg={8} className={classes.leftSide}>
            <Block>
              {stepper}
              <Box mt={4} onClick={handleClickStartLink}>
                <CanSendTagContext.Provider value={false}>{startLinkFullWidth}</CanSendTagContext.Provider>
              </Box>
            </Block>
          </Grid>
          <Hidden lgUp>
            <Separator />
          </Hidden>
        </Grid>
      </Wrapper>

      {/* Video */}
      {videosList && (
        <Wrapper className={classes.section}>
          <h2>{t[language].common.see_more}</h2>
          {videosList}
        </Wrapper>
      )}

      {/* Related questions */}
      {(!panels || panels.length > 0) && (
        <Wrapper className={classes.section}>
          <h2>{t[language].process.questions_title}</h2>
          {relatedQuestions}
        </Wrapper>
      )}
    </Fragment>
  );
};

Process.propTypes = {
  page: PropTypes.shape().isRequired,
};

export default Process;
