import React, { useState, useEffect, createRef, Fragment, useContext } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import scrollIntoView from "scroll-into-view-if-needed";
import Wrapper from "components/LayoutFront/Wrapper";
import Block from "components/templatesComponents/Block";
import Hidden from "components/templatesComponents/Hidden";
import Icon from "components/templatesComponents/Icon";
import { ImageContainer } from "components/templatesComponents/Image";
import PageTitle from "components/templatesComponents/PageTitle";
import InfoGeoloc from "components/templates/geolocalisation/InfoGeoloc";
import SearchBar from "components/templates/geolocalisation/SearchBar";
import MessageContext from "components/MessageContext";
import GeolocMapHeader from "components/templates/geolocalisation/GeolocMapHeader";
import GeolocMobileTabs from "components/templates/geolocalisation/GeolocMobileTabs";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import LanguageContext from "components/LanguageContext";
import t from "utils/locales/translation.json";
import categories from "components/templates/geolocalisation/geolocalisation.categories.enum.json";

import { eventKey } from "utils/commonUtils";

const useStyles = makeStyles((theme) => ({
  wrapperProps: {
    position: "relative",
    maxWidth: "1440px",
    margin: "0 auto",
  },
  imageContainer: {
    position: "absolute",
    top: 16,
    right: 0,
    width: "40%",
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "block",
    },
  },
  input: {
    backgroundColor: "white",
    border: "1px solid #bbb",
    height: theme.spacing(7),
    padding: theme.spacing(1, 2),
    fontSize: "0.875rem",
    "& input": {
      height: "1.4rem",
    },
  },
  seachBar: {
    minHeight: 56,
    maxWidth: 325,
    padding: 0,
  },
  LocalisationContainer: {
    display: "flex",
  },
  geoPointContainer: {
    padding: theme.spacing(3, 4),
    display: "flex",
  },
  sideContainer: {
    position: "relative",
    [theme.breakpoints.up("lg")]: {
      maxHeight: 498,
      overflowX: "hidden",
      overflowY: "auto",
    },
    "& > div:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.secondary.main}`,
    },
  },
  leaflet: {
    "& .leaflet-control > a": {
      backgroundImage: "none",
    },
  },
  Popup: {
    borderRadius: 0,
    boxShadow: "none",
  },
  goToMap: {
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
    marginLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    "& > i,span": {
      fontSize: "26px",
      color: theme.palette.componentColors[60],
    },
    cursor: "pointer",
  },
  resultNumber: {
    margin: theme.spacing(0, 2, 1, 2),
    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(1.5, 0),
    },
  },
  loader: {
    color: theme.palette.secondary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginLeft: "-20px",
    marginTop: "-20px",
  },
  geoContainer: {
    minHeight: 500,
    [theme.breakpoints.up("lg")]: {
      border: `1px solid ${theme.palette.secondary.main}`,
    },
    /* width */
    "& ::-webkit-scrollbar": {
      width: 8,
    },
    /* Track */
    "& ::-webkit-scrollbar-track": {
      borderRadius: 10,
    },
    /* Handle */
    "& ::-webkit-scrollbar-thumb": {
      background: "#b3b3b3",
      borderRadius: 8,
    },
    /* Handle on hover */
    "& ::-webkit-scrollbar-thumb:hover": {
      background: "#939393",
    },
  },
  tabMap: {
    "& div": {
      padding: theme.spacing(2),
      border: `1px solid ${theme.palette.secondary.main}`,
      textAlign: "center",
    },
  },
  active: {
    border: "none",
    background: theme.palette.secondary.main,
    color: theme.palette.getContrastText(theme.palette.secondary.main),
  },
  textAlign: {
    textAlign: "center",
    position: "relative",
    [theme.breakpoints.up("lg")]: {
      textAlign: "start",
    },
  },
  searchLabel: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("lg")]: {
      marginBottom: theme.spacing(2),
    },
    "& > i,span": {
      color: theme.palette.primary.main,
      fontSize: "1.125rem",
      paddingLeft: "8px",
    },
  },
  hiddenDesktop: {
    width: "100%",
    position: "sticky",
    zIndex: 3,
    top: 0,
    backgroundColor: "#f5f5f5",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  title: {
    [theme.breakpoints.down("md")]: {
      fontSize: "1.125rem",
      margin: "1.5rem 0",
    },
  },
  separator: {
    height: theme.spacing(3),
    [theme.breakpoints.up("lg")]: {
      height: theme.spacing(8),
    },
  },
  map: {
    width: "100%",
    height: "498px",
    zIndex: 1,
  },
}));

const Geolocalisation = (props) => {
  const classes = useStyles();
  const { page } = props;

  const { title, image, shortDescription, contents } = page;

  const { language } = useContext(LanguageContext);
  const { displayError } = useContext(MessageContext);

  const { description } = contents;
  let { source } = contents;
  let editSourceComponent = null;
  if (React.isValidElement(source)) {
    editSourceComponent = source;
    source = source.props.content?.value;
  }

  const radius1 = process.env.geoloc_radius_1;
  const radius2 = process.env.geoloc_radius_2;

  const [mapComponents, setMapComponents] = useState({});
  const [position, setPosition] = useState([46.7667, 2.45]); // Centre France metroplitaine
  const [cityPosition, setCityPosition] = useState("");
  const [activeTab, setActiveTab] = useState("1");
  const [geoPoints, setGeoPoints] = useState([]);
  const [centerOnClick, setCenterOnClick] = useState([]); // centre la carte sur le point séléctionné en mobile
  const [geoPointsRefs, setGeoPointsRefs] = useState({});
  const [beforeSearch, setBeforeSearch] = useState(true);
  const [geoPointsRefsText, setGeoPointsRefsText] = useState({});
  const [load, setLoad] = useState(false);
  const [inputValue, setInputValue] = React.useState("");

  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("lg"));

  const getLocationIcon = (cat) => {
    if (window) {
      // eslint-disable-next-line global-require
      const L = require("leaflet");
      return L.icon({
        iconUrl: `/location-icon-${cat?.toLowerCase() === categories[source].specific ? "rouge" : "bleu"}.png`,
        shadowUrl: iconShadow,
        iconSize: [36, 36],
        iconAnchor: [16, 36],
        popupAnchor: [0, -40],
      });
    }
    return null;
  };

  useEffect(() => {
    if (window) {
      // eslint-disable-next-line global-require
      require("leaflet/dist/leaflet.css");
      // eslint-disable-next-line global-require
      require("./geo.css");
      // eslint-disable-next-line global-require
      setMapComponents(require("react-leaflet"));
    }
  }, []);

  useEffect(() => {
    const refs = {};
    const refsText = {};
    geoPoints.forEach((geoPointItem) => {
      refs[geoPointItem._source.customId] = createRef();
      refsText[geoPointItem._source.customId] = createRef();
    });
    setGeoPointsRefs(refs);
    setGeoPointsRefsText(refsText);
  }, [geoPoints]);

  const getInfo = (lat, long, size = 100) => {
    axios
      .post(`/geoloc`, {
        lat,
        long,
        source,
        distance: size === 100 ? radius1 : radius2,
        from: 0,
        size,
      })
      .then(({ data }) => {
        if (data?.hits?.total?.value <= size) {
          if (/^\d+$/.test(inputValue.trim())) {
            setGeoPoints(
              data?.hits?.hits?.filter((hit) => (hit?._source?.others?.postalCode || "").trim() === inputValue.trim())
            );
          } else {
            setGeoPoints(data?.hits?.hits || []);
          }
          setLoad(false);
        } else {
          getInfo(lat, long, data?.hits?.total?.value);
        }
      });
  };

  useEffect(() => {
    if (position && position.length > 0 && !beforeSearch) {
      setLoad(true);
      getInfo(position[0], position[1]);
    }
    setCenterOnClick([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position, source]);

  const showLocation = (positionNav) => {
    const { latitude, longitude } = positionNav.coords;
    setBeforeSearch(false);
    setPosition([latitude, longitude]);
  };

  const errorHandler = (err) => {
    let message = "";
    switch (err.code) {
      case 1:
        message = "Vous n'avez pas accès à la géolocalisation";
        break;
      case 2:
        message = "Votre position est indisponible";
        break;
      case 3:
        message = "Le delai de la requête est dépassé";
        break;
      default:
        message = "Une erreur est survenue";
    }
    displayError(message);
  };

  // Obtenir la localisation depuis le naviagteur
  const getLocation = () => {
    if (navigator.geolocation) {
      // timeout at 60000 milliseconds (60 seconds)
      const options = { timeout: 60000 };
      navigator.geolocation.getCurrentPosition(showLocation, errorHandler, options);
      setGeoPoints([]);
      setCityPosition("votre position");
      setInputValue("");
    } else {
      alert(t[language].geolocalisation.browser_alert);
    }
  };

  const mapRef = createRef();

  const handleMouseOverGeoPoint = (point) => () => {
    const ref = geoPointsRefs[point._source.customId] && geoPointsRefs[point._source.customId].current;
    const refText = geoPointsRefsText[point._source.customId].current;
    if (!isMobile) {
      // eslint-disable-next-line prefer-destructuring
      refText.style.backgroundColor = theme.palette.secondary[10];
    }
    if (ref && ref.leafletElement && typeof ref.leafletElement.openPopup === "function") {
      ref.leafletElement.openPopup();
    }
  };

  const handleClickGeoPoint = (point) => {
    const ref = geoPointsRefs[point._source.customId] && geoPointsRefs[point._source.customId].current;
    const refText = geoPointsRefsText[point._source.customId] && geoPointsRefsText[point._source.customId].current;
    if (isMobile) {
      const [latitude, longitude] = (point._source.location && point._source.location.split(",")) || [];
      setActiveTab("1");
      setCenterOnClick([latitude, longitude]);
      if (document) {
        scrollIntoView(document.getElementById("mapAnchorStart"), {
          block: "nearest",
          inline: "nearest",
        });
      }
    }
    if (ref && ref.leafletElement && typeof ref.leafletElement.openPopup === "function") {
      ref.leafletElement.openPopup();
    }
    if (!isMobile) {
      // eslint-disable-next-line prefer-destructuring
      refText.style.backgroundColor = theme.palette.secondary[10];
      scrollIntoView(refText, {
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }
  };

  const handleClickPopup = (point) => () => {
    const refText = geoPointsRefsText[point._source.customId].current;
    setActiveTab("2");
    setTimeout(() => {
      scrollIntoView(refText, {
        block: "nearest",
        inline: "nearest",
      });
    }, 0);
    // eslint-disable-next-line prefer-destructuring
    refText.style.backgroundColor = theme.palette.secondary[10];
  };

  const handleMouseLeave = () => {
    Object.entries(geoPointsRefs).forEach((geoPointItem) => {
      geoPointItem[1].current.leafletElement.closePopup();
    });
  };

  const handlePopupClose = (point) => () => {
    const ref2 = geoPointsRefsText[point._source.customId].current;
    ref2.style.backgroundColor = "transparent";
  };

  geoPoints.forEach((geoPointItem) => {
    // eslint-disable-next-line no-param-reassign
    geoPointItem.opening = [];
    geoPointItem.opening.push(geoPointItem._source.others.openingTimeMonday);
    geoPointItem.opening.push(geoPointItem._source.others.openingTimeTuesday);
    geoPointItem.opening.push(geoPointItem._source.others.openingTimeWednesday);
    geoPointItem.opening.push(geoPointItem._source.others.openingTimeThursday);
    geoPointItem.opening.push(geoPointItem._source.others.openingTimeFriday);
    geoPointItem.opening.push(geoPointItem._source.others.openingTimeSaturday);
    geoPointItem.opening.push(geoPointItem._source.others.openingTimeSunday);
  });

  const { Map, Marker, Popup, TileLayer } = mapComponents;

  const invalidateMap = () => {
    setTimeout(() => {
      if (mapRef && mapRef.current && mapRef.current.leafletElement) {
        mapRef.current.leafletElement.invalidateSize(false);
      }
      return null;
    }, 0);
  };

  useEffect(() => {
    if (mapRef?.current?.leafletElement) {
      mapRef.current.container.removeAttribute("tabindex");
    }
  });

  return (
    <Wrapper wrapperProps={{ className: classes.wrapperProps }}>
      <Grid container justifyContent="space-between">
        <Grid item lg={image ? 6 : 12}>
          {!!editSourceComponent && <Block>{editSourceComponent}</Block>}
          <Block>
            <PageTitle>{title}</PageTitle>
          </Block>
          {shortDescription && <Block>{shortDescription}</Block>}
          {description && <Block>{description}</Block>}
        </Grid>
      </Grid>
      {image && (
        <Hidden mdDown>
          <div className={classes.imageContainer}>
            <ImageContainer ratio={48}>{image}</ImageContainer>
          </div>
        </Hidden>
      )}

      <div id="searchAnchorStart" />
      <Block>
        <p className={classes.searchLabel} role="presentation">
          {t[language].geolocalisation.input_search_label}
          <Icon
            icon="info-circle"
            iconDSFR="information-fill"
            type="fas"
            title={t[language].geolocalisation.input_search_tooltip}
          />
        </p>
        <div className={classes.LocalisationContainer}>
          <SearchBar
            setPosition={setPosition}
            setCityPosition={setCityPosition}
            setGeoPoints={setGeoPoints}
            inputValue={inputValue}
            setInputValue={setInputValue}
            setBeforeSearch={setBeforeSearch}
          />
          <button
            type="button"
            className="fr-btn"
            aria-label={t[language].geolocalisation.icon_tooltip}
            onClick={getLocation}
            style={{ marginLeft: theme.spacing(2) }}
          >
            {!isMobile && <p style={{ marginRight: theme.spacing(1) }}>{t[language].geolocalisation.locate}</p>}
            <Icon icon="location" iconDSFR="map-pin-user-line" title={t[language].geolocalisation.icon_tooltip} />
          </button>
        </div>
      </Block>
      <div className={classes.resultNumber}>
        {!beforeSearch && !load && (
          <GeolocMapHeader geoPointsLength={geoPoints.length} cityPosition={cityPosition} source={source} />
        )}
      </div>
      <Box px={{ xs: 2, md: 0 }}>
        <div id="mapAnchorStart" />
        <GeolocMobileTabs classes={classes} activeTab={activeTab} setActiveTab={setActiveTab} />
        <Grid container className={classes.geoContainer} onMouseLeave={handleMouseLeave}>
          <Grid
            className={classes.sideContainer}
            item
            lg={4}
            xs={12}
            style={{ display: isMobile && activeTab === "1" ? "none" : undefined }}
          >
            <Fragment>{load && <CircularProgress className={classes.loader} />}</Fragment>
            {geoPoints.map(
              (geoPointItem) =>
                geoPointItem._source.others && (
                  <div
                    className={classes.geoPointContainer}
                    onMouseOver={handleMouseOverGeoPoint(geoPointItem)}
                    ref={geoPointsRefsText[geoPointItem._source.customId]}
                    key={geoPointItem._source.id}
                  >
                    <InfoGeoloc geoPointItem={geoPointItem} cityPosition={cityPosition} source={source} />
                    <div
                      className={classes.goToMap}
                      aria-hidden="true"
                      tabIndex={0}
                      onClick={() => handleClickGeoPoint(geoPointItem)}
                      onKeyDown={(e) => e.key === eventKey && handleClickGeoPoint(geoPointItem)}
                    >
                      <Icon
                        icon="map-marked-alt"
                        iconDSFR="road-map-line"
                        title={t[language].geolocalisation.view_map}
                      />
                    </div>
                  </div>
                )
            )}
          </Grid>
          <Grid
            item
            xs={12}
            lg={8}
            className={classes.leaflet}
            style={{ display: isMobile && activeTab === "2" ? "none" : undefined, position: "relative" }}
            aria-hidden
          >
            {!!Map && (
              <Map
                center={isMobile && centerOnClick.length > 1 ? centerOnClick : position}
                zoom={beforeSearch ? 5.5 : 10.5}
                maxZoom={15}
                className={classes.map}
                ref={mapRef}
                zoomControl={!isMobile}
                onDragEnd={invalidateMap()}
              >
                <TileLayer url={`${process.env.geoloc_url}/{z}/{x}/{y}.png`} attribution={process.env.geoloc_licence} />

                {geoPoints.map(
                  (geoPointItem) =>
                    geoPointItem._source &&
                    geoPointItem._source.location && (
                      <Marker
                        icon={getLocationIcon(geoPointItem._source.category)}
                        position={geoPointItem._source.location.split(",")}
                        ref={geoPointsRefs[geoPointItem._source.customId]}
                        onMouseOver={handleMouseOverGeoPoint(geoPointItem)}
                        onClick={() => !isMobile && handleClickGeoPoint(geoPointItem)}
                        onPopupClose={handlePopupClose(geoPointItem)}
                        key={geoPointItem._source.id}
                      >
                        <Popup>
                          <div onClick={handleClickPopup(geoPointItem)}>
                            <p className="fr-text--bold" style={{ margin: "1rem 0" }} role="presentation">
                              {geoPointItem._source.others.name || ""}
                            </p>
                            <p style={{ margin: 0 }} role="presentation">
                              {source && source === "siv"
                                ? `${geoPointItem._source.others.numVoie || ""} ${
                                    geoPointItem._source.others.typeVoie || ""
                                  } ${geoPointItem._source.others.libelleVoie || ""}`
                                : `${geoPointItem._source.others.address || ""} ${
                                    geoPointItem._source.others.address2 || ""
                                  }`}
                            </p>
                            <p style={{ margin: "0 0 4px 0" }} role="presentation">
                              {geoPointItem._source.others.postalCode || ""} {geoPointItem._source.others.city || ""}
                            </p>
                            {isMobile && (
                              <p
                                style={{ margin: "0 0 4px 0", color: theme.palette.secondary.main }}
                                role="presentation"
                              >
                                {t[language].geolocalisation.see_more_infos}
                              </p>
                            )}
                          </div>
                        </Popup>
                      </Marker>
                    )
                )}
              </Map>
            )}
          </Grid>
        </Grid>
      </Box>
    </Wrapper>
  );
};

Geolocalisation.propTypes = {
  page: PropTypes.shape().isRequired,
};

Geolocalisation.defaultProps = {};

export default Geolocalisation;
